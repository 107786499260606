import { IEducation, IUserEntitlementType } from '@api';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { SyntheticEvent, useState } from 'react';
import { Button, Can, Modal } from '~/components';
import { useEducationDownloadLocation } from '~/hooks';
import { getIconFromExtension } from '~/pages/business/present/documentCenter/icons/iconMap';
import { getColorFromIcon } from '~/pages/business/present/documentCenter/utils/colorMap';
import { defaultErrorMessage } from '~/utils/errorUtils';

interface IProps extends IEducation {
    onEdit?: () => void;
    onError: (message: string) => void;
}

export const EducationItem = ({ onEdit, onError, ...education }: IProps): JSX.Element => {
    const icon = getIconFromExtension(education.fileName);
    const color = getColorFromIcon(icon.iconName);
    const [documentPreview, setDocumentPreview] = useState<{ isOpen: boolean; src?: string }>({
        isOpen: false,
    });
    const getDownloadLocation = useEducationDownloadLocation();

    const handlePreview = async (e: SyntheticEvent) => {
        try {
            e.stopPropagation();
            const downloadLocation = await getDownloadLocation(education.id);
            const filePreviewUrl = await fetch(downloadLocation.url)
                .then(r => r.blob())
                .then(blobFile => new File([blobFile], education.fileName, { type: downloadLocation.contentType }))
                .then(URL.createObjectURL);
            setDocumentPreview({ isOpen: true, src: filePreviewUrl });
        } catch (err: unknown) {
            onError(`Failed to download ${education.title || education.fileName}. ${defaultErrorMessage}`);
        }
    };

    return (
        <div className="flex flex-col space-between bg-background1 rounded-md shadow-lg px-5 py-4 h-48">
            <div className="text-lg font-medium break-words mb-2 line-clamp-2 flex-shrink-0">
                {education.title || education.fileName}
            </div>
            {education.author && (
                <div className="italic text-textSubtle truncate text-sm mb-2 flex-shrink-0">
                    Author: {education.author}
                </div>
            )}
            {education.description && (
                <div className="!overflow-y-auto text-textSubtle flex-shrink-1 line-clamp-3 mb-1">
                    {education.description}
                </div>
            )}
            <div className="mt-auto flex justify-between pt-1">
                <Button
                    color="link"
                    className="hover:text-textSubtle"
                    iconClassName={color}
                    iconLeft={getIconFromExtension(education.fileName)}
                    iconSize="lg"
                    text="View PDF"
                    onClick={handlePreview}
                />
                <Can hasEntitlement={IUserEntitlementType.EducationUpdate} condition={!!onEdit}>
                    <Button color="primaryLink" iconLeft={faEdit} text="Edit" onClick={onEdit} />
                </Can>
            </div>
            <Modal
                maxWidth="7xl"
                open={documentPreview.isOpen}
                title="View PDF"
                setOpen={() => {
                    setDocumentPreview({ ...documentPreview, isOpen: false });
                    URL.revokeObjectURL(documentPreview.src || '');
                }}
            >
                <iframe
                    className="w-full"
                    src={documentPreview.src}
                    style={{ minHeight: '80vh' }}
                    title="Education resource preview"
                ></iframe>
            </Modal>
        </div>
    );
};
