import { AdvisorsApi, IAdvisor, IAdvisorCreateRequest, IAdvisorUpdateRequest, IAvatar, IProblemDetails } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getAdvisorFirmQueryKey } from '.';

const advisorsApi = new AdvisorsApi(undefined, config.api.baseUrl);
const advisorsQueryKey = 'advisors';

const getAdvisorsQueryKey = (advisorFirmId: string) => getAdvisorFirmQueryKey(advisorFirmId, advisorsQueryKey);

const getAdvisorQueryKey = (advisorId: string, ...rest: string[]): QueryKey => [
    advisorsQueryKey,
    advisorId,
    ...(rest || []),
];

export const useAddAdvisor = (
    advisorFirmId: string
): UseMutationResult<IAdvisor, IProblemDetails, IAdvisorCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAdvisor, IProblemDetails, IAdvisorCreateRequest>(
        request => advisorsApi.postAdvisor(undefined, request).then(response => response.data),
        {
            onSuccess: ({ id: advisorId }) => invalidateAdvisorQueries(queryClient, advisorFirmId, advisorId),
        }
    );

    return mutation;
};

export const useAdvisorsByFirmId = (advisorFirmId: string): UseQueryResult<IAdvisor[], IProblemDetails> =>
    useQuery(
        getAdvisorsQueryKey(advisorFirmId),
        () => advisorsApi.getAdvisorsByFirmId(advisorFirmId, undefined).then(response => response.data),
        {
            enabled: !!advisorFirmId,
        }
    );

export const useAdvisor = (advisorId: string, enabled = true): UseQueryResult<IAdvisor, IProblemDetails> =>
    useQuery(getAdvisorQueryKey(advisorId), () => advisorsApi.getAdvisor(advisorId).then(response => response.data), {
        enabled: !!advisorId && enabled,
    });

export const useDeleteAdvisor = (advisorFirmId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        advisorId => advisorsApi.deleteAdvisor(advisorId, undefined).then(response => response.data),
        {
            onSuccess: (_, advisorId) => invalidateAdvisorQueries(queryClient, advisorFirmId, advisorId, true),
        }
    );

    return mutation;
};

export const useUpdateAdvisor = (
    advisorFirmId: string,
    advisorId: string
): UseMutationResult<void, IProblemDetails, IAdvisorUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IAdvisorUpdateRequest>(
        request => advisorsApi.putAdvisor(advisorId, undefined, request).then(response => response.data),
        {
            onSuccess: () => invalidateAdvisorQueries(queryClient, advisorFirmId, advisorId),
        }
    );

    return mutation;
};

interface IAdvisorAvatarParams {
    advisorId: string;
    avatar: File;
}

export const useAdvisorAvatar = (
    advisorFirmId: string
): UseMutationResult<IAvatar, IProblemDetails, IAdvisorAvatarParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAvatar, IProblemDetails, IAdvisorAvatarParams>(
        ({ advisorId, avatar }: IAdvisorAvatarParams) =>
            advisorsApi.setAdvisorAvatar(advisorId, avatar).then(response => response.data),
        {
            onSuccess: (_, { advisorId }) => invalidateAdvisorQueries(queryClient, advisorFirmId, advisorId),
        }
    );

    return mutation;
};

function invalidateAdvisorQueries(
    queryClient: QueryClient,
    advisorFirmId: string,
    advisorId: string,
    skipInvalidate?: boolean
) {
    queryClient.resetQueries(getAdvisorsQueryKey(advisorFirmId));
    if (!skipInvalidate) {
        queryClient.invalidateQueries(getAdvisorQueryKey(advisorId));
    }
}
