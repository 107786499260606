import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useContext } from 'react';
import { MagicLinkContext } from '~/auth/MagicLinkWrapper';
import { useMagicLink } from './magicLink';

interface AuthContextInterface {
    error?: Error;
    getAccessTokenSilently: () => Promise<string>;
    isAuthenticated: boolean;
    isLoading: boolean;
    loginWithRedirect: () => Promise<void>;
    logout: () => void;
}

export const useAuth = (): AuthContextInterface => {
    const { authenticated, magicLinkToken, setMagicLinkToken, setAccessToken, setAuthenticated } =
        useContext(MagicLinkContext);
    const okta = useOktaAuth();
    const magicLink = useMagicLink(magicLinkToken ?? '');

    const magicLinkGetAccessTokenSilently = useCallback(async () => magicLink.data?.accessToken ?? '', [magicLink]);

    const magicLinkIsAuthenticated = authenticated;

    const magicLinkLoginWithRedirect = useCallback(async () => {
        setAuthenticated(true);
    }, [setAuthenticated]);

    const magicLinkLogout = useCallback(() => {
        setAccessToken(undefined);
        setAuthenticated(false);
        setMagicLinkToken(undefined);
    }, [setAccessToken, setAuthenticated, setMagicLinkToken]);

    const magicLinkFunctions = {
        error: magicLink.error ? new Error() : undefined,
        getAccessTokenSilently: magicLinkGetAccessTokenSilently,
        isAuthenticated: magicLinkIsAuthenticated,
        isLoading: magicLink.isLoading,
        loginWithRedirect: magicLinkLoginWithRedirect,
        logout: magicLinkLogout,
    };

    const authProviderFunctions = {
        error: okta.authState?.error,
        getAccessTokenSilently: async () => {
            const token = okta.oktaAuth.getAccessToken();
            if (token === undefined) okta.oktaAuth.signOut({ revokeAccessToken: false });
            return token ?? '';
        },
        isAuthenticated: okta.authState?.isAuthenticated ?? false,
        isLoading: okta.authState === null,
        loginWithRedirect: async () => {
            const previousAuthState = okta.oktaAuth?.authStateManager.getPreviousAuthState();
            if (!previousAuthState || !previousAuthState.isAuthenticated) {
                // App initialization stage
                await okta.oktaAuth?.signInWithRedirect({ originalUri: '/' });
            }
        },
        logout: () => okta.oktaAuth.signOut({ revokeAccessToken: false }),
    };

    return magicLinkToken ? magicLinkFunctions : authProviderFunctions;
};
